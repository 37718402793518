import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DropDown from "nlib/ui/DropDown";
import DropDownCaret from "nlib/ui/DropDown/lib/DropDownCaret";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownMenuDivider from "nlib/ui/DropDown/lib/DropDownMenuDivider";
import DropDownMenuItem from "nlib/ui/DropDown/lib/DropDownMenuItem";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import React, { useCallback } from "react";
import TaskFilters from "const/TaskFilters";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const { ALL_TASKS } = TaskFilters;

const SelectTasksFilter = ({ className, options, modal }) => {
  const [{ tasksFilter = ALL_TASKS }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const selectedOption = options.find((option) => option.value === tasksFilter);

  const handleItemClick = useCallback((value) => {
    setEnvVars({ tasksFilter: value });
  }, [setEnvVars]);

  return (
    <div className={classNames(Css.selectTasksFilter, className)}>
      <DropDown>
        <DropDownToggle className={Css.toggle}>
          <div className={Css.label}>
            <span className={Css.text} title={selectedOption.label}>
              {selectedOption.label}
            </span>
            <DropDownCaret />
          </div>
          {!Utils.checkIsTouchDevice() && (
            <div className={Css.incompleted}>
              {isNaN(selectedOption.completed)
                ? Utils.replaceTextVars(uiTexts.countTasks, { count: selectedOption.all })
                : Utils.replaceTextVars(uiTexts.countTasksIncompleted, { count: selectedOption.all - selectedOption.completed })}
            </div>
          )}
        </DropDownToggle>
        <DropDownContent
          portal
          title={uiTexts.tasks}
          modal={modal}>
          <div className={Css.dropDownContent}>
            {options.map(({ divider, label, value }, index) => {
              const key = String(index);

              if (divider) return <DropDownMenuDivider key={key} />;

              return (
                <DropDownMenuItem key={key} value={value} onClick={handleItemClick}>
                  {label}
                </DropDownMenuItem>
              );
            })}
          </div>
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default React.memo(SelectTasksFilter);
