import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Badge from "nlib/ui/Badge";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Features from "const/Features";
import NoDataContent from "nlib/common/NoDataContent";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectTasksFilter from "nlib/common/SelectTasksFilter";
import Switcher from "nlib/ui/Switcher";
import TaskFilters from "const/TaskFilters";
import TaskListItem from "nlib/common/TaskListItem";
import UiActions from "actions/UiActions";
import useAllowedFeature from "hooks/useAllowedFeature";
import useEnvVars from "hooks/useEnvVars";
import useTasks from "hooks/useTasks";

const {
  ACTIVE_FOR_BUSINESS,
  ACTIVE_FOR_ACCOUNTANT,
  ALL_FOR_BUSINESS,
  ALL_FOR_ACCOUNTANT,
  ALL_ACTIVE_TASKS,
  ALL_TASKS
} = TaskFilters;

const TasksPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [envVars, setEnvVars] = useEnvVars();

  const checkIsAllowed = useAllowedFeature();

  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    fetchingData,
    tasksFilter,
    allTasksData,
    filteredTasksData,
    allActiveTasksData,
    tasksForBusinessData,
    tasksForAccountantData,
    activeTasksForBusinessData,
    activeTasksForAccountantData
  } = useTasks();

  const userData = useSelector(getUserData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const [showForAll, setShowForAll] = useState(true);

  const selectorOptions = useMemo(() => {
    const closedTasksForBusinessCount = tasksForBusinessData.length - activeTasksForBusinessData.length;

    const closedTasksForAccountantCount = tasksForAccountantData.length - activeTasksForAccountantData.length;

    const closedTasksCount = allTasksData.length - allActiveTasksData.length;

    return [
      {
        label: `${uiTexts.tasksForClient} (${closedTasksForBusinessCount}/${tasksForBusinessData.length})`,
        completed: closedTasksForBusinessCount,
        all: tasksForBusinessData.length,
        value: ALL_FOR_BUSINESS
      },
      {
        label: `${uiTexts.activeTasksForClient} (${activeTasksForBusinessData.length})`,
        all: activeTasksForBusinessData.length,
        value: ACTIVE_FOR_BUSINESS
      },
      { divider: true },
      {
        label: `${uiTexts.tasksForAccountant} (${closedTasksForAccountantCount}/${tasksForAccountantData.length})`,
        completed: closedTasksForAccountantCount,
        all: tasksForAccountantData.length,
        value: ALL_FOR_ACCOUNTANT
      },
      {
        label: `${uiTexts.activeTasksForAccountant} (${activeTasksForAccountantData.length})`,
        all: activeTasksForAccountantData.length,
        value: ACTIVE_FOR_ACCOUNTANT
      },
      { divider: true },
      {
        label: `${uiTexts.allTasks} (${closedTasksCount}/${allTasksData.length})`,
        completed: closedTasksCount,
        all: allTasksData.length,
        value: ALL_TASKS
      },
      {
        label: `${uiTexts.allActiveTasks} (${allActiveTasksData.length})`,
        all: allActiveTasksData.length,
        value: ALL_ACTIVE_TASKS
      }
    ];
  }, [
    activeTasksForAccountantData.length,
    activeTasksForBusinessData.length,
    allActiveTasksData.length,
    allTasksData.length,
    tasksForAccountantData.length,
    tasksForBusinessData.length,
    uiTexts
  ]);

  const tasksOnlyForCurrentUser = filteredTasksData ? filteredTasksData.filter((task) => {
    const { targetUser } = task;

    const targetUserId = targetUser ? targetUser.id : null;

    return targetUserId === userData.id;
  }) : [];

  const switcherOptions = useMemo(() => {
    return [
      {
        value: true,
        label: (
          <>
            <span>{uiTexts.forAll}</span>
            <Badge counter className={Css.counter}>{filteredTasksData.length}</Badge>
          </>
        )
      },
      {
        value: false,
        label: (
          <>
            <span>{uiTexts.onlyForMe}</span>
            <Badge counter className={Css.counter}>{tasksOnlyForCurrentUser.length}</Badge>
          </>
        )
      }
    ];
  }, [filteredTasksData.length, tasksOnlyForCurrentUser.length, uiTexts]);

  const tasksToDisplay = showForAll ? filteredTasksData : tasksOnlyForCurrentUser;

  const handleAddTaskButtonClick = useCallback(() => {
    setEnvVars({ editTask: Constants.NEW_ENTITY_ID, editItem: null });
  }, [setEnvVars]);

  const handleSelectTask = useCallback((taskId) => {
    setEnvVars({ editTask: taskId, editItem: null });
  }, [setEnvVars]);

  const handleSwitcherChange = useCallback((value) => {
    setShowForAll(value);
  }, []);

  useEffect(() => {
    if (!checkIsAllowed(Features.TASKS)) {
      history.replace(`/${selectedBusinessId}`);
    }
  }, [selectedBusinessId, history, checkIsAllowed]);

  useEffect(() => {
    dispatch(UiActions.setTasksFilter(tasksFilter));
  }, [dispatch, tasksFilter]);

  return (
    <Page className={Css.tasksPage}>
      <PageContent>
        <PageHeader>
          <div disabled={fetchingData} className={Css.headerContent}>
            {!!allTasksData.length && (
              <>
                <SelectTasksFilter
                  className={Css.filter}
                  options={selectorOptions} />
                <Switcher
                  light large
                  className={Css.switcher}
                  value={showForAll}
                  options={switcherOptions}
                  onChange={handleSwitcherChange} />
                {(envVars.editTask !== Constants.NEW_ENTITY_ID) && (
                  <Button
                    large primary
                    className={Css.button}
                    icon={Icons.Plus}
                    onClick={handleAddTaskButtonClick}>
                    {uiTexts.addNewTask}
                  </Button>
                )}
              </>
            )}
          </div>
        </PageHeader>
        {allTasksData.length
          ? (tasksToDisplay.map((task) => (
            <TaskListItem
              key={task.id}
              task={task}
              disabled={fetchingData}
              onSelect={handleSelectTask} />
          )))
          : (fetchingData ? <Preloader absolute /> : (
            <NoDataContent
              description={messages.tasksBlockPlaceholder}>
              <Button
                large primary
                icon={Icons.Plus}
                onClick={handleAddTaskButtonClick}>
                {uiTexts.addNewTask}
              </Button>
            </NoDataContent>
          ))}
      </PageContent>
    </Page>
  );
};

export default React.memo(TasksPage);
